import { TOGGLE_NEW_REVIEWERS, UPDATE_NEW_REVIEWERS, TOGGLE_SEND_SUCCESS_MODAL } from "@actions/preview/send"
import { CREATE_SHARED_SCREENERS } from "@actions/shared_screeners/"

import uuidv4 from "uuid/v4"

const defaultWatermarking = {
  text: "Protected Content.",
  position: "bottom-right",
  second_line_text_type: null,
  watermarkEnabled: false,
  size: "medium", // small, medium, large
  opacity: 75 // 50, 75, 100
}

const initialState = {
  visible: false,
  mode: "",
  type: "",
  email: {
    title: "Share by Email",
    status: "READY",
    ...defaultWatermarking,
    list: {
      [uuidv4()]: {
        email: "",
        full_name: ""
      }
    },
    message: "",
    step: "emails",
    organizationId: null,
    screenerRoomId: null,
    showPasswordField: false,
    password: "",
    send_email_notification: false,
    geoblock_country_codes: "",
    view_count_limit: 10,
    expiry: null,
    available_at: null,
    ip_protection_enabled: false,
    commenting_enabled: false
  },
  link: {
    title: "Generate Sharing URL",
    full_name: "",
    email: "",
    ...defaultWatermarking,
    step: "emails",
    showPasswordField: false,
    password: "",
    send_email_notification: false,
    geoblock_country_codes: "",
    view_count_limit: 10,
    ip_protection_enabled: false,
    commenting_enabled: false
  },
  showSuccessModal: false
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_NEW_REVIEWERS:
      if (state.visible) return initialState
      else
        return {
          ...state,
          visible: true,
          mode: action.mode,
          shareType: action.shareType,
          can_use_forensic_watermark: action.canUseForensicWatermark,
          [action.mode]: {
            ...state[action.mode],
            use_forensic_watermark: action.useForensicWatermark
          }
        }

    case UPDATE_NEW_REVIEWERS:
      return {
        ...state,
        [action.kind]: {
          ...state[action.kind],
          [action.key]: action.value
        }
      }

    case CREATE_SHARED_SCREENERS.PENDING:
      return {
        ...state,
        link: {
          ...state.link,
          status: "PENDING"
        },
        email: {
          ...state.email,
          status: "PENDING"
        }
      }

    case CREATE_SHARED_SCREENERS.COMPLETE:
      return {
        ...initialState,
        showSuccessModal: true,
        sharedScreeners: action.payload.shared_screeners.list
      }

    case TOGGLE_SEND_SUCCESS_MODAL:
      return {
        ...state,
        showSuccessModal: !state.showSuccessModal
      }

    default:
      return state
  }
}
